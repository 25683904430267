@import "variables";

@font-face {
  font-family: '@{icomoon-font-family}';
  src:  url('@{icomoon-font-path}/@{icomoon-font-family}.eot?32962n');
  src:  url('@{icomoon-font-path}/@{icomoon-font-family}.eot?32962n#iefix') format('embedded-opentype'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.ttf?32962n') format('truetype'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.woff?32962n') format('woff'),
    url('@{icomoon-font-path}/@{icomoon-font-family}.svg?32962n#@{icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="tz-"], [class*=" tz-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '@{icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tz-search {
  &:before {
    content: @tz-search; 
  }
}
.tz-settings {
  &:before {
    content: @tz-settings; 
  }
}
.tz-choose-20 {
  &:before {
    content: @tz-choose-20; 
  }
}
.tz-user-owner-20 {
  &:before {
    content: @tz-user-owner-20; 
  }
}
.tz-export-template-20 {
  &:before {
    content: @tz-export-template-20; 
  }
}
.tz-added-20 {
  &:before {
    content: @tz-added-20; 
  }
}
.tz-toast-warning-32 {
  &:before {
    content: @tz-toast-warning-32; 
  }
}
.tz-toast-success-32 {
  &:before {
    content: @tz-toast-success-32; 
  }
}
.tz-toast-info-32 {
  &:before {
    content: @tz-toast-info-32; 
  }
}
.tz-toast-error-32 {
  &:before {
    content: @tz-toast-error-32; 
  }
}
.tz-presale-control-process-16 {
  &:before {
    content: @tz-presale-control-process-16; 
  }
}
.tz-choice-circle-24 {
  &:before {
    content: @tz-choice-circle-24; 
  }
}
.tz-operational-control-process-16 {
  &:before {
    content: @tz-operational-control-process-16; 
  }
}
.tz-warranty-service-process-16 {
  &:before {
    content: @tz-warranty-service-process-16; 
  }
}
.tz-units-handover-process-16 {
  &:before {
    content: @tz-units-handover-process-16; 
  }
}
.tz-internal-acceptance-process-16 {
  &:before {
    content: @tz-internal-acceptance-process-16; 
  }
}
.tz-acceptance-control-process-16 {
  &:before {
    content: @tz-acceptance-control-process-16; 
  }
}
.tz-repeat-16 {
  &:before {
    content: @tz-repeat-16; 
  }
}
.tz-fields-28 {
  &:before {
    content: @tz-fields-28; 
  }
}
.tz-logo-latin {
  &:before {
    content: @tz-logo-latin; 
  }
}
.tz-logo-cyrillic {
  &:before {
    content: @tz-logo-cyrillic; 
  }
}
.tz-empty-table {
  &:before {
    content: @tz-empty-table; 
  }
}
.tz-attach-20 {
  &:before {
    content: @tz-attach-20; 
  }
}
.tz-links-list {
  &:before {
    content: @tz-links-list; 
  }
}
.tz-custom-rhombus-20 {
  &:before {
    content: @tz-custom-rhombus-20; 
  }
}
.tz-custom-socket-20 {
  &:before {
    content: @tz-custom-socket-20; 
  }
}
.tz-custom-lamp-20 {
  &:before {
    content: @tz-custom-lamp-20; 
  }
}
.tz-custom-shovel-20 {
  &:before {
    content: @tz-custom-shovel-20; 
  }
}
.tz-custom-exclamation-point-20 {
  &:before {
    content: @tz-custom-exclamation-point-20; 
  }
}
.tz-custom-rectangles-20 {
  &:before {
    content: @tz-custom-rectangles-20; 
  }
}
.tz-custom-block-20 {
  &:before {
    content: @tz-custom-block-20; 
  }
}
.tz-custom-snowflake-20 {
  &:before {
    content: @tz-custom-snowflake-20; 
  }
}
.tz-custom-shield-20 {
  &:before {
    content: @tz-custom-shield-20; 
  }
}
.tz-custom-triangle-20 {
  &:before {
    content: @tz-custom-triangle-20; 
  }
}
.tz-custom-hexagon-20 {
  &:before {
    content: @tz-custom-hexagon-20; 
  }
}
.tz-custom-polygon-20 {
  &:before {
    content: @tz-custom-polygon-20; 
  }
}
.tz-Icon1 {
  &:before {
    content: @tz-Icon1;     
    color: #fff;
  }
}
.tz-inspection-add-24 {
  &:before {
    content: @tz-inspection-add-24; 
  }
}
.tz-problem-add-24 {
  &:before {
    content: @tz-problem-add-24; 
  }
}
.tz-task-add-24 {
  &:before {
    content: @tz-task-add-24; 
  }
}
.tz-check-list-categories-20 {
  &:before {
    content: @tz-check-list-categories-20; 
  }
}
.tz-space-16 {
  &:before {
    content: @tz-space-16; 
  }
}
.tz-tile-24 {
  &:before {
    content: @tz-tile-24; 
  }
}
.tz-attach-16 {
  &:before {
    content: @tz-attach-16; 
  }
}
.tz-document-16 {
  &:before {
    content: @tz-document-16; 
  }
}
.tz-problem-24 {
  &:before {
    content: @tz-problem-24; 
  }
}
.tz-link-20 {
  &:before {
    content: @tz-link-20; 
  }
}
.tz-bank-account-28 {
  &:before {
    content: @tz-bank-account-28; 
  }
}
.tz-classifier-20 {
  &:before {
    content: @tz-classifier-20; 
  }
}
.tz-address-20 {
  &:before {
    content: @tz-address-20; 
  }
}
.tz-phone-20 {
  &:before {
    content: @tz-phone-20; 
  }
}
.tz-legal-entity-28 {
  &:before {
    content: @tz-legal-entity-28; 
  }
}
.tz-legal-entity-20 {
  &:before {
    content: @tz-legal-entity-20; 
  }
}
.tz-modified-20 {
  &:before {
    content: @tz-modified-20; 
  }
}
.tz-number-20 {
  &:before {
    content: @tz-number-20; 
  }
}
.tz-price-20 {
  &:before {
    content: @tz-price-20; 
  }
}
.tz-stage-20 {
  &:before {
    content: @tz-stage-20; 
  }
}
.tz-bank-account-20 {
  &:before {
    content: @tz-bank-account-20; 
  }
}
.tz-contract-28 {
  &:before {
    content: @tz-contract-28; 
  }
}
.tz-contractor-20 {
  &:before {
    content: @tz-contractor-20; 
  }
}
.tz-created-20 {
  &:before {
    content: @tz-created-20; 
  }
}
.tz-file-type-pdf {
  &:before {
    content: @tz-file-type-pdf; 
  }
}
.tz-file-type-doc {
  &:before {
    content: @tz-file-type-doc; 
  }
}
.tz-file-type-xls {
  &:before {
    content: @tz-file-type-xls; 
  }
}
.tz-inspector-20 {
  &:before {
    content: @tz-inspector-20; 
  }
}
.tz-task-16 {
  &:before {
    content: @tz-task-16; 
  }
}
.tz-comment-20 {
  &:before {
    content: @tz-comment-20; 
  }
}
.tz-problem-20 {
  &:before {
    content: @tz-problem-20; 
  }
}
.tz-percent-20 {
  &:before {
    content: @tz-percent-20; 
  }
}
.tz-unit-20 {
  &:before {
    content: @tz-unit-20; 
  }
}
.tz-task-type-20 {
  &:before {
    content: @tz-task-type-20; 
  }
}
.tz-star-priority-20 {
  &:before {
    content: @tz-star-priority-20; 
  }
}
.tz-outline-object-20 {
  &:before {
    content: @tz-outline-object-20; 
  }
}
.tz-structure {
  &:before {
    content: @tz-structure; 
  }
}
.tz-inspection-16 {
  &:before {
    content: @tz-inspection-16; 
  }
}
.tz-company-20 {
  &:before {
    content: @tz-company-20; 
  }
}
.tz-check-24 {
  &:before {
    content: @tz-check-24; 
  }
}
.tz-owner-acceptance-24 {
  &:before {
    content: @tz-owner-acceptance-24; 
  }
}
.tz-task-28 {
  &:before {
    content: @tz-task-28; 
  }
}
.tz-check-28 {
  &:before {
    content: @tz-check-28; 
  }
}
.tz-owner-acceptance-28 {
  &:before {
    content: @tz-owner-acceptance-28; 
  }
}
.tz-drag-and-drop-24 {
  &:before {
    content: @tz-drag-and-drop-24; 
  }
}
.tz-task-24 {
  &:before {
    content: @tz-task-24; 
  }
}
.tz-warning-20 {
  &:before {
    content: @tz-warning-20; 
  }
}
.tz-category-20 {
  &:before {
    content: @tz-category-20; 
  }
}
.tz-priority-highest-16 {
  &:before {
    content: @tz-priority-highest-16; 
  }
}
.tz-priority-high-16 {
  &:before {
    content: @tz-priority-high-16; 
  }
}
.tz-priority-medium-16 {
  &:before {
    content: @tz-priority-medium-16; 
  }
}
.tz-notifications-default-entity {
  &:before {
    content: @tz-notifications-default-entity; 
  }
}
.tz-notifications-default-type {
  &:before {
    content: @tz-notifications-default-type; 
  }
}
.tz-notifications-attachment {
  &:before {
    content: @tz-notifications-attachment; 
  }
}
.tz-notifications-message {
  &:before {
    content: @tz-notifications-message; 
  }
}
.tz-notifications-inspection {
  &:before {
    content: @tz-notifications-inspection; 
  }
}
.tz-notifications-status {
  &:before {
    content: @tz-notifications-status; 
  }
}
.tz-notifications-add {
  &:before {
    content: @tz-notifications-add; 
  }
}
.tz-notifications-edit {
  &:before {
    content: @tz-notifications-edit; 
  }
}
.tz-notifications-delete {
  &:before {
    content: @tz-notifications-delete; 
  }
}
.tz-prescription-20 {
  &:before {
    content: @tz-prescription-20; 
  }
}
.tz-eraser-24 {
  &:before {
    content: @tz-eraser-24; 
  }
}
.tz-brush-24 {
  &:before {
    content: @tz-brush-24; 
  }
}
.tz-brush-20 {
  &:before {
    content: @tz-brush-20; 
  }
}
.tz-space-type-20 {
  &:before {
    content: @tz-space-type-20; 
  }
}
.tz-attachment {
  &:before {
    content: @tz-attachment; 
  }
}
.tz-chat {
  &:before {
    content: @tz-chat; 
  }
}
.tz-email {
  &:before {
    content: @tz-email; 
  }
}
.tz-reason-external-link {
  &:before {
    content: @tz-reason-external-link; 
  }
}
.tz-find {
  &:before {
    content: @tz-find; 
  }
}
.tz-fix-date {
  &:before {
    content: @tz-fix-date; 
  }
}
.tz-toast-info {
  &:before {
    content: @tz-toast-info; 
  }
}
.tz-toast-success {
  &:before {
    content: @tz-toast-success; 
  }
}
.tz-toast-warning {
  &:before {
    content: @tz-toast-warning; 
  }
}
.tz-problem-hexagon {
  &:before {
    content: @tz-problem-hexagon; 
  }
}
.tz-few-sample {
  &:before {
    content: @tz-few-sample; 
  }
}
.tz-not-accepted {
  &:before {
    content: @tz-not-accepted; 
  }
}
.tz-repeat-sample {
  &:before {
    content: @tz-repeat-sample; 
  }
}
.tz-check-mark {
  &:before {
    content: @tz-check-mark; 
  }
}
.tz-percent {
  &:before {
    content: @tz-percent; 
  }
}
.tz-logo {
  &:before {
    content: @tz-logo; 
  }
}
.tz-cloud {
  &:before {
    content: @tz-cloud; 
  }
}
.tz-cloud-caching-active {
  &:before {
    content: @tz-cloud-caching-active; 
  }
}
.tz-cloud-caching-done {
  &:before {
    content: @tz-cloud-caching-done; 
  }
}
.tz-cloud-caching-error {
  &:before {
    content: @tz-cloud-caching-error; 
  }
}
.tz-cloud-sync {
  &:before {
    content: @tz-cloud-sync; 
  }
}
.tz-arrow-16 {
  &:before {
    content: @tz-arrow-16; 
  }
}
.tz-arrow-20 {
  &:before {
    content: @tz-arrow-20; 
  }
}
.tz-long-arrow-24 {
  &:before {
    content: @tz-long-arrow-24; 
  }
}
.tz-arrow-24 {
  &:before {
    content: @tz-arrow-24; 
  }
}
.tz-long-arrow {
  &:before {
    content: @tz-long-arrow; 
  }
}
.tz-simple-arrow-16 {
  &:before {
    content: @tz-simple-arrow-16; 
  }
}
.tz-simple-arrow-20 {
  &:before {
    content: @tz-simple-arrow-20; 
  }
}
.tz-simple-arrow-24 {
  &:before {
    content: @tz-simple-arrow-24; 
  }
}
.tz-simple-arrow-28 {
  &:before {
    content: @tz-simple-arrow-28; 
  }
}
.tz-expand-arrow-heavy {
  &:before {
    content: @tz-expand-arrow-heavy; 
  }
}
.tz-corner-arrow {
  &:before {
    content: @tz-corner-arrow; 
  }
}
.tz-reload-20 {
  &:before {
    content: @tz-reload-20; 
  }
}
.tz-reload-24 {
  &:before {
    content: @tz-reload-24; 
  }
}
.tz-status-20 {
  &:before {
    content: @tz-status-20; 
  }
}
.tz-calendar-20 {
  &:before {
    content: @tz-calendar-20; 
  }
}
.tz-calendar-24 {
  &:before {
    content: @tz-calendar-24; 
  }
}
.tz-calendar-done-20 {
  &:before {
    content: @tz-calendar-done-20; 
  }
}
.tz-location-20 {
  &:before {
    content: @tz-location-20; 
  }
}
.tz-indicator-20 {
  &:before {
    content: @tz-indicator-20; 
  }
}
.tz-area-20 {
  &:before {
    content: @tz-area-20; 
  }
}
.tz-space-owner-20 {
  &:before {
    content: @tz-space-owner-20; 
  }
}
.tz-description-20 {
  &:before {
    content: @tz-description-20; 
  }
}
.tz-external-link-16 {
  &:before {
    content: @tz-external-link-16; 
  }
}
.tz-external-link-20 {
  &:before {
    content: @tz-external-link-20; 
  }
}
.tz-photo-20 {
  &:before {
    content: @tz-photo-20; 
  }
}
.tz-document-20 {
  &:before {
    content: @tz-document-20; 
  }
}
.tz-edit-16 {
  &:before {
    content: @tz-edit-16; 
  }
}
.tz-edit-20 {
  &:before {
    content: @tz-edit-20; 
  }
}
.tz-clock {
  &:before {
    content: @tz-clock; 
  }
}
.tz-alarm-clock {
  &:before {
    content: @tz-alarm-clock; 
  }
}
.tz-plan {
  &:before {
    content: @tz-plan; 
  }
}
.tz-map {
  &:before {
    content: @tz-map; 
  }
}
.tz-menu {
  &:before {
    content: @tz-menu; 
  }
}
.tz-more-16 {
  &:before {
    content: @tz-more-16; 
  }
}
.tz-more-24 {
  &:before {
    content: @tz-more-24; 
  }
}
.tz-edit {
  &:before {
    content: @tz-edit; 
  }
}
.tz-delete {
  &:before {
    content: @tz-delete; 
  }
}
.tz-copy {
  &:before {
    content: @tz-copy; 
  }
}
.tz-user-20 {
  &:before {
    content: @tz-user-20; 
  }
}
.tz-user-24 {
  &:before {
    content: @tz-user-24; 
  }
}
.tz-users-24 {
  &:before {
    content: @tz-users-24; 
  }
}
.tz-add-user-24 {
  &:before {
    content: @tz-add-user-24; 
  }
}
.tz-camera-24 {
  &:before {
    content: @tz-camera-24; 
  }
}
.tz-exit {
  &:before {
    content: @tz-exit; 
  }
}
.tz-comment {
  &:before {
    content: @tz-comment; 
  }
}
.tz-comment-88 {
  &:before {
    content: @tz-comment-88; 
  }
}
.tz-plus-16 {
  &:before {
    content: @tz-plus-16; 
  }
}
.tz-plus-20 {
  &:before {
    content: @tz-plus-20; 
  }
}
.tz-plus-24 {
  &:before {
    content: @tz-plus-24; 
  }
}
.tz-minus {
  &:before {
    content: @tz-minus; 
  }
}
.tz-attach-24 {
  &:before {
    content: @tz-attach-24; 
  }
}
.tz-send {
  &:before {
    content: @tz-send; 
  }
}
.tz-mark-16 {
  &:before {
    content: @tz-mark-16; 
  }
}
.tz-mark-2-16 {
  &:before {
    content: @tz-mark-2-16; 
  }
}
.tz-mark-24 {
  &:before {
    content: @tz-mark-24; 
  }
}
.tz-close-16 {
  &:before {
    content: @tz-close-16; 
  }
}
.tz-close-20 {
  &:before {
    content: @tz-close-20; 
  }
}
.tz-close-24 {
  &:before {
    content: @tz-close-24; 
  }
}
.tz-table-sort-20 {
  &:before {
    content: @tz-table-sort-20; 
  }
}
.tz-filters-24 {
  &:before {
    content: @tz-filters-24; 
  }
}
.tz-export-20 {
  &:before {
    content: @tz-export-20; 
  }
}
.tz-export-24 {
  &:before {
    content: @tz-export-24; 
  }
}
.tz-bar-chart {
  &:before {
    content: @tz-bar-chart; 
  }
}
.tz-pie-chart {
  &:before {
    content: @tz-pie-chart; 
  }
}
.tz-line-chart {
  &:before {
    content: @tz-line-chart; 
  }
}
.tz-company-28 {
  &:before {
    content: @tz-company-28; 
  }
}
.tz-object-28 {
  &:before {
    content: @tz-object-28; 
  }
}
.tz-internal-acceptance-28 {
  &:before {
    content: @tz-internal-acceptance-28; 
  }
}
.tz-internal-acceptance-24 {
  &:before {
    content: @tz-internal-acceptance-24; 
  }
}
.tz-problem-16 {
  &:before {
    content: @tz-problem-16; 
  }
}
.tz-problem-28 {
  &:before {
    content: @tz-problem-28; 
  }
}
.tz-problem {
  &:before {
    content: @tz-problem; 
  }
}
.tz-problem-waiting {
  &:before {
    content: @tz-problem-waiting; 
  }
}
.tz-problem-handling {
  &:before {
    content: @tz-problem-handling; 
  }
}
.tz-problem-verification {
  &:before {
    content: @tz-problem-verification; 
  }
}
.tz-problem-fixed {
  &:before {
    content: @tz-problem-fixed; 
  }
}
.tz-problem-expired {
  &:before {
    content: @tz-problem-expired; 
  }
}
.tz-problem-created {
  &:before {
    content: @tz-problem-created; 
  }
}
.tz-comment-40 {
  &:before {
    content: @tz-comment-40; 
  }
}
.tz-inspection {
  &:before {
    content: @tz-inspection; 
  }
}
.tz-inspection-28 {
  &:before {
    content: @tz-inspection-28; 
  }
}
.tz-space {
  &:before {
    content: @tz-space; 
  }
}
.tz-space-24 {
  &:before {
    content: @tz-space-24; 
  }
}
.tz-space-40 {
  &:before {
    content: @tz-space-40; 
  }
}
.tz-space-status-28 {
  &:before {
    content: @tz-space-status-28; 
  }
}
.tz-warranty-claim-24 {
  &:before {
    content: @tz-warranty-claim-24; 
  }
}
.tz-warranty-claim-28 {
  &:before {
    content: @tz-warranty-claim-28; 
  }
}
.tz-statistics-28 {
  &:before {
    content: @tz-statistics-28; 
  }
}
.tz-user-28 {
  &:before {
    content: @tz-user-28; 
  }
}
.tz-role-28 {
  &:before {
    content: @tz-role-28; 
  }
}
.tz-export-template-28 {
  &:before {
    content: @tz-export-template-28; 
  }
}
.tz-reg-standard-28 {
  &:before {
    content: @tz-reg-standard-28; 
  }
}
.tz-log-28 {
  &:before {
    content: @tz-log-28; 
  }
}
.tz-help {
  &:before {
    content: @tz-help; 
  }
}
.tz-docx {
  &:before {
    content: @tz-docx; 
  }
}
.tz-xlsx {
  &:before {
    content: @tz-xlsx; 
  }
}
.tz-pdf {
  &:before {
    content: @tz-pdf; 
  }
}
.tz-file {
  &:before {
    content: @tz-file; 
  }
}
.tz-circle-warning-32 {
  &:before {
    content: @tz-circle-warning-32; 
  }
}
.tz-search-20 {
  &:before {
    content: @tz-search-20; 
  }
}
.tz-info-20 {
  &:before {
    content: @tz-info-20; 
  }
}
.tz-info-24 {
  &:before {
    content: @tz-info-24; 
  }
}
.tz-settings-24 {
  &:before {
    content: @tz-settings-24; 
  }
}
.tz-name-20 {
  &:before {
    content: @tz-name-20; 
  }
}
.tz-list-24 {
  &:before {
    content: @tz-list-24; 
  }
}
.tz-schema-24 {
  &:before {
    content: @tz-schema-24; 
  }
}
.tz-delegate-20 {
  &:before {
    content: @tz-delegate-20; 
  }
}
.tz-delegate-24 {
  &:before {
    content: @tz-delegate-24; 
  }
}
.tz-critical-20 {
  &:before {
    content: @tz-critical-20; 
  }
}
.tz-history-24 {
  &:before {
    content: @tz-history-24; 
  }
}
.tz-mark-20 {
  &:before {
    content: @tz-mark-20; 
  }
}
.tz-offline-mode-20 {
  &:before {
    content: @tz-offline-mode-20; 
  }
}
.tz-data-transfer-error-20 {
  &:before {
    content: @tz-data-transfer-error-20; 
  }
}
.tz-data-transfer-20 {
  &:before {
    content: @tz-data-transfer-20; 
  }
}
.tz-password-visible-20 {
  &:before {
    content: @tz-password-visible-20; 
  }
}
.tz-password-hidden-20 {
  &:before {
    content: @tz-password-hidden-20; 
  }
}
.tz-mail-20 {
  &:before {
    content: @tz-mail-20; 
  }
}
.tz-tag-20 {
  &:before {
    content: @tz-tag-20; 
  }
}
.tz-object-24 {
  &:before {
    content: @tz-object-24; 
  }
}
.tz-comment-on-plan-16 {
  &:before {
    content: @tz-comment-on-plan-16; 
  }
}
.tz-work-acceptance-28 {
  &:before {
    content: @tz-work-acceptance-28; 
  }
}
.tz-work-acceptance-16 {
  &:before {
    content: @tz-work-acceptance-16; 
  }
}
.tz-export-template-manage-28 {
  &:before {
    content: @tz-export-template-manage-28; 
  }
}
.tz-tag-manage-28 {
  &:before {
    content: @tz-tag-manage-28; 
  }
}
.tz-category-manage-28 {
  &:before {
    content: @tz-category-manage-28; 
  }
}
.tz-space-type-manage-28 {
  &:before {
    content: @tz-space-type-manage-28; 
  }
}
.tz-group-manage-28 {
  &:before {
    content: @tz-group-manage-28; 
  }
}
.tz-check-list-16 {
  &:before {
    content: @tz-check-list-16; 
  }
}
.tz-check-list-manage-28 {
  &:before {
    content: @tz-check-list-manage-28; 
  }
}
.tz-space-status-manage-28 {
  &:before {
    content: @tz-space-status-manage-28; 
  }
}
.tz-eye-20 {
  &:before {
    content: @tz-eye-20; 
  }
}
.tz-document-type-20 {
  &:before {
    content: @tz-document-type-20; 
  }
}
.tz-document-format-20 {
  &:before {
    content: @tz-document-format-20; 
  }
}
.tz-customer-20 {
  &:before {
    content: @tz-customer-20; 
  }
}
.tz-heat-20 {
  &:before {
    content: @tz-heat-20; 
  }
}
.tz-gas-20 {
  &:before {
    content: @tz-gas-20; 
  }
}
.tz-electricity-20 {
  &:before {
    content: @tz-electricity-20; 
  }
}
.tz-water-cold-20 {
  &:before {
    content: @tz-water-cold-20; 
  }
}
.tz-water-hot-20 {
  &:before {
    content: @tz-water-hot-20; 
  }
}
.tz-presale-check-28 {
  &:before {
    content: @tz-presale-check-28; 
  }
}

